import React, { useMemo } from "react"
import { useIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"
import SEO from "components/_common/Page/SEO"
import Layout from "components/_common/Page/Layout"
import withNoIndex from "components/_common/NoIndex/withNoIndex"
import { getPathFromSlug, isValidArray } from "helpers"
import { isChinese, isDefault } from "helpers/locales"
import { LANGUAGES, LANGUAGES_ARRAY, ROUTES } from "consts"

function findLayout(layouts, language) {
  if (!isValidArray(layouts)) {
    throw new TypeError("layouts should be an array")
  }

  let lang = language

  if (isDefault(language)) {
    lang = LANGUAGES.STORYBLOK_DEFAULT
  }

  if (isChinese(language)) {
    lang = LANGUAGES.STORYBLOK_CHINESE
  }

  const filtered = layouts.filter(({ node }) => node.lang === lang)[0].node
  const parsed = JSON.parse(filtered.content)

  return {
    footer: parsed.footer[0],
    navigationBar: parsed.navigationBar[0],
  }
}

// TODO: Move to Storyblok
const NotFoundPage = ({ data }) => {
  const intl = useIntl()

  const layout = useMemo(() => findLayout(data.layout.edges, intl.locale), [
    data.layout,
    intl.locale,
  ])

  return (
    <>
      <SEO
        data={{ title: intl.formatMessage({ id: "Page Not Found" }) }}
        path={ROUTES.NOT_FOUND_PRESLASH}
        langLinks={LANGUAGES_ARRAY}
      />
      <Layout
        slug={ROUTES.NOT_FOUND_PRESLASH}
        footer={layout.footer}
        navigation={layout.navigationBar}
        path={getPathFromSlug(ROUTES.NOT_FOUND_PRESLASH)}
        langLinks={LANGUAGES_ARRAY}
      >
        <div className="main-container fourOfour">
          <div className="center">
            <h1>{intl.formatMessage({ id: "Page Not Found" })}</h1>
            <p>
              {intl.formatMessage({
                id: "Oops, we couldn't find this page!",
              })}
            </p>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default withNoIndex(NotFoundPage)

export const pageQuery = graphql`
  query {
    layout: allStoryblokEntry(filter: { name: { eq: "Layout" } }) {
      edges {
        node {
          content
          lang
        }
      }
    }
  }
`
